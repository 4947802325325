// @ts-nocheck
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  account: {
    '&.MuiButtonBase-root': {
      padding: 0,
    },
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '32px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
  icon: {
    width: '27px',
    marginRight: '6px',
  },
  menuTitle: {
    color: (props) => props?.color || '#000000',
    fontSize: '16px',
    marginLeft: '8px',
  },
  popoverPaper: {
    top: '60px !important',
    [theme.breakpoints.down('xs')]: {
      position: 'sticky',
      minWidth: '100%',
      borderRadius: '0',
    },
  },
  popoverRoot: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      top: '60px !important',
    },
  },
  arrow: {
    color: (props) => props?.color || '#000000',
    fontSize: '18px',
  },
  langTitle: {
    color: (props) => props?.color || '#000000',
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
  },
  menuListItem: {
    padding: '12px 16px',
    color: 'inherit',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
  flag: {
    width: '20px',
  },
}));

export default styles;
