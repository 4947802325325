export default {
  subtitleGrey: '#687285',
  mainGreen: '#5fbb50',
  hoverDarkGreen: '#469339',
  lightenGreen: '#E9F4E7',
  mediumGreen: '#A5D399',
  darkGreen: '#5DBA50',
  darkestGrey: '#52545E',
  mainGrey: '#A7A7AF',
  darkGrey: '#67676B',
  darkest2Grey: '#424448',
  backgroundGrey: '#303642',
  mediumGrey: '#9E9A9C',
  grey79: '#797982',
  lightestGrey: '#E4E7E9',
  lightGrey: '#cfcfd3',
  textGrey: '#637381',
  white: '#ffffff',
  red: '#f25656',
  borderGrey: '#919EAB',
  placeholderColor: '#949DB0',
  greyIcon: '#212B36',
  disabledGrey: ' rgba(145, 158, 171, 0.24)',
  disabledText: 'rgba(148, 157, 176, 0.8)',
  borderCardGrey: 'rgba(145, 158, 171, 0.32)',
  borderInputGrey: 'rgba(148, 157, 176, 0.32)',
  borderLightGrey: 'rgba(229, 232, 235, 0.5)',
  grey5: '#E5E5E5',
  error: '#FF4842',
  darkRed: '#de242b',
  lightBackgroundGrey: '#F4F6F8',
  warning: '#FFC107',
  chartGrey: 'rgba(145, 158, 171, 0.16)',
  lighterBlue: '#6d9ee3',
  mainBlue: '#0C53B7',
  darkError: '#B72136',
  transparentRed: 'rgba(255, 72, 66, 0.16)',
  warningDark: '#B78103',
  warningTransparent: 'rgba(255, 193, 7, 0.16)',
  blueTransparent: 'rgba(24, 144, 255, 0.16)',
  darkestGreen: '#3E7B34',
  greenTransparent: 'rgba(95, 187, 80, 0.16)',
  blueLink: '#1890FF',
  grey800: '#DFE3E8',
  warningLighter: '#FFF7CD',
  darkGreyV2: '#21252E',
  darkGreyV2Hover: '#4D5564',
};
