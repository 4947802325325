import React from 'react';
import { Icon } from '@zaveit/icons';
import useStyles from './Notifications.styles';

const EmptyView = () => {
  const classes = useStyles({});

  return (
    <div className={classes.placeholderContainer}>
      <div className={classes.placeholderIconContainer}>
        <Icon iconName="notifications" className={classes.placeholderIcon} />
      </div>
      <div className={classes.placeholderDescription}>
        No notifications yet. We’ll let you{'\n'} know when we get news for you.
      </div>
    </div>
  );
};

export default EmptyView;
