import * as React from 'react';
import { TextField } from '@material-ui/core';
import useStyles from './TextField.styles';

type TTextField = {
  field: any;
  label?: string;
  errors?: any;
  touched?: any;
  onChange?: (e: any) => void;
};

function TextFieldComponent({ field, label, errors, touched, onChange }: TTextField) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextField
        {...field}
        variant="outlined"
        label={label}
        className={classes.textField}
        fullWidth
        value={field.value || ''}
        onChange={onChange || field.onChange}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            error: classes.errorInput,
          },
          type: 'text',
        }}
      />
      {errors && touched ? <div className={classes.error}>{errors}</div> : null}
    </div>
  );
}

TextFieldComponent.defaultProps = {
  label: '',
  errors: null,
  touched: null,
  onChange: () => {},
};

export default TextFieldComponent;
