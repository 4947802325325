import * as React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

import useStyles from './Button.styles';
import { getData } from '../../helpers';
import ColorStyles from '../../styles/Color.styles';

export type ButtonStyle =
  | 'activeGreen'
  | 'borderedGreen'
  | 'transparentButton'
  | 'transparentGreenText'
  | 'transparentGreyBorderBtn'
  | 'activeGrey'
  | 'backButton'
  | 'greyBtn'
  | 'redButton'
  | 'transparentBtn'
  | 'rootBtn'
  | 'active';

export interface TButton extends ButtonProps {
  children: any;
  onClick?: (values: any | undefined) => void | Promise<void>;
  styleClass: ButtonStyle;
  disabledClass?: string;
  disabled?: boolean;
  type?: any;
  width?: string;
  marginTop?: string;
  marginRight?: string;
  height?: string;
  nextButton?: boolean;
  backButton?: boolean;
  icon?: any;
  loading?: boolean;
  backgroundColor?: string;
  rootClasses?: string;
}
const Button = React.forwardRef<HTMLButtonElement, TButton>(
  (
    {
      children,
      onClick,
      styleClass,
      disabled,
      disabledClass,
      type,
      width,
      height,
      marginTop,
      nextButton,
      marginRight,
      backButton,
      icon,
      loading,
      backgroundColor,
      rootClasses,
      ...props
    },
    ref,
  ) => {
    const settingsData = getData('siteSettings');
    const colors = settingsData?.colors;
    const primaryColor = colors?.primary_color || ColorStyles.mainGreen;
    const classes = useStyles({
      width,
      marginTop,
      height,
      marginRight,
      loading,
      backgroundColor,
      primaryColor,
    });

    const customRootClasses = classNames(classes[styleClass], rootClasses);

    return (
      <MuiButton
        ref={ref}
        onClick={onClick}
        className={classes.rootBtn}
        classes={{
          root: customRootClasses,
          disabled: classes[disabledClass],
        }}
        disabled={disabled || loading}
        type={type}
        {...props}
      >
        {loading ? (
          <CircularProgress size={22} className={classes.loader} />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </MuiButton>
    );
  },
);

Button.defaultProps = {
  disabled: false,
  disabledClass: '',
  type: 'button',
  onClick: () => {},
  width: '',
  marginTop: '',
  height: '',
  marginRight: '',
  nextButton: false,
  backButton: false,
  icon: '',
  loading: false,
  backgroundColor: null,
  rootClasses: null,
};

export default Button;
