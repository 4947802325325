import React, { useRef, useState, useEffect, useCallback } from 'react';
import { IconButton, Menu } from '@material-ui/core';

import { getEnvironment } from 'api-client';
import useStyles from './DemoMenu.styles';

const DemoMenu = ({ urlConfig }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [showDemo, setShowDemo] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current?.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const handleGetEnvironment = useCallback(async () => {
    const data = await getEnvironment(urlConfig);
    setShowDemo(data?.demo);
  }, [urlConfig]);

  useEffect(() => {
    handleGetEnvironment();
  }, [handleGetEnvironment]);

  if (!showDemo) {
    return null;
  }

  return (
    <div className={classes.containerRoot}>
      <IconButton
        ref={anchorRef}
        aria-label="Notifications"
        onClick={handleToggle}
        classes={{
          root: classes.notificationBtn,
        }}
      >
        <div className={classes.title}>Demo</div>
      </IconButton>
      <Menu
        disableScrollLock
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        elevation={0}
        PopoverClasses={{
          paper: classes.popoverPaper,
          root: classes.popoverRoot,
        }}
        id="menu-appbar"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 60,
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <div className={classes.menuContent}>
          <div className={classes.menuTitle}>Demo Environment</div>
          <div className={classes.menuDescr}>
            This is a free version and you don’t have to worry about getting billed for any actions
            inside this demo.
          </div>
          <div className={classes.menuDescr}>
            Feel free to explore and if you have any feedback, we&apos;d love to hear it. Enjoy!
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default DemoMenu;
