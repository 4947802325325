import axios from 'axios';

const getInstance = (config) =>
  axios.create({ baseURL: config.basePublicUrl, withCredentials: true });

export const getStatus = (config) => {
  const instance = getInstance(config);
  return instance.get('/v1/health');
};

export const getLatestHealth = (config) => {
  const instance = getInstance(config);
  return instance.get('/v1/health/version');
};

export const getAccountSetting = (config) => {
  const instance = getInstance(config);
  return instance.get('/v1/account/setting/my');
};
