import React from 'react';
import { Dialog, Slide, useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from './Modal.styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-expect-error
  return <Slide direction="up" ref={ref} {...props} />;
});

type TModal = {
  children: any;
  open: boolean;
  handleClose?: () => void;
};

export default function AlertDialogSlide({ handleClose, open, children }: TModal) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      fullScreen={fullScreen}
      // @ts-expect-error
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      classes={{
        paper: classes.root,
        root: classes.backDrop,
      }}
    >
      {children}
    </Dialog>
  );
}

AlertDialogSlide.defaultProps = {
  handleClose: () => {},
};
