import { darken, lighten, makeStyles } from '@material-ui/core';
import Colors from '../../styles/Color.styles';

const styles = () => ({
  rootBtn: {
    '&.MuiButtonBase-root': {
      padding: 0,
      borderRadius: '8px',
      fontFamily: 'Lato-Bold, sans-serif',
      fontSize: '14px',
      textTransform: 'none',
      color: Colors.white,
      boxSizing: 'border-box',
    },
  },
  loader: {
    color: Colors.white,
  },
  activeGreen: {
    '&.MuiButtonBase-root': {
      width: (props) => props.width || '83px',
      height: (props) => props.height || '36px',
      padding: '0px 5px',
      backgroundColor: (props) => props?.primaryColor || Colors.mainGreen,
      boxShadow: (props) =>
        props?.primaryColor
          ? `0px 8px 16px ${lighten(props?.primaryColor, 0.8)}`
          : '0px 8px 16px rgba(95, 187, 80, 0.24)',
    },
    '&:hover': {
      backgroundColor: (props) =>
        props?.primaryColor ? darken(props?.primaryColor, 0.5) : Colors.hoverDarkGreen,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: (props) =>
        props.loading ? props?.primaryColor || Colors.mainGreen : Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  transparentButton: {
    '&.MuiButtonBase-root': {
      width: (props) => props.width || '75px',
      height: (props) => props.height || '36px',
      marginTop: (props) => props?.marginTop || '0px',
      marginRight: (props) => props?.marginRight,
      padding: '0px 5px',
      backgroundColor: 'transparent',
      border: '1px solid rgba(145, 158, 171, 0.32)',
      color: Colors.backgroundGrey,
      fontSize: '14px',
    },
    '&:hover': {
      backgroundColor: Colors.grey5,
      boxShadow: 'none',
    },
    '&:disabled': {
      color: Colors.disabledText,
    },
  },
});

export default makeStyles(styles);
