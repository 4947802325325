import axios from 'axios';

export const getEndUserAgreement = async (config) => {
  try {
    const { data } = await axios.get(`${config.baseAuthUrl}/v1/agreement/user/unsigned`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

type SignedAgreementPostData = {
  user_id: string;
  tenant: string;
  username: string;
  full_name: string;
  browser: string;
  ip: string;
  data: Array<string>;
};

export const saveSignedAgreement = (data: SignedAgreementPostData, config) => {
  return axios.post(`${config.baseAuthUrl}/v1/signed-agreement`, {
    ...data,
  });
};
