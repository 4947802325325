import io from 'socket.io-client';
import defaultConfig from 'config';

export const initiateSocketConnection = (config) => {
  const url = config?.baseNotificationUrl || defaultConfig?.baseNotificationUrl;

  return io(url, {
    withCredentials: true,
    path: '/v1/notification/soketio/',
  });
};
