import { makeStyles } from '@material-ui/core';
import ColorStyles from './Color.styles';

const styles = makeStyles(() => ({
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    marginRight: '8px',
  },
  blueAvatar: {
    color: ColorStyles.blueText,
    backgroundColor: ColorStyles.backgroundBlue,
  },
  greenAvatar: {
    color: ColorStyles.greenText,
    backgroundColor: ColorStyles.lighterGreen,
  },
  yellowAvatar: {
    color: ColorStyles.yelowText,
    backgroundColor: ColorStyles.lighterYellow,
  },
  pinkAvatar: {
    color: ColorStyles.mainPink,
    backgroundColor: ColorStyles.backgroundPink,
  },
  grapeAvatar: {
    color: ColorStyles.mainGrape,
    backgroundColor: ColorStyles.backgroundGrape,
  },
  violetAvatar: {
    color: ColorStyles.violetText,
    backgroundColor: ColorStyles.lighterViolet,
  },
  indigoAvatar: {
    color: ColorStyles.indigoText,
    backgroundColor: ColorStyles.lighterIndigo,
  },
  cyanAvatar: {
    color: ColorStyles.cyanText,
    backgroundColor: ColorStyles.lighterCyan,
  },
  tealAvatar: {
    color: ColorStyles.tealText,
    backgroundColor: ColorStyles.lighterTeal,
  },
  limeAvatar: {
    color: ColorStyles.limeText,
    backgroundColor: ColorStyles.lighterLime,
  },
  orangeAvatar: {
    color: ColorStyles.orangeText,
    backgroundColor: ColorStyles.lighterOrange,
  },
}));

export default styles;
