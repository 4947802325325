import React from 'react';
import classNames from 'classnames';
import useStyles from './Avatar.styles';

interface Props {
  letter: string;
}

const Avatar = ({ letter }: Props) => {
  const classes = useStyles();

  const handleGetAvatarClass = (value) => {
    let additionalClass = '';
    switch (true) {
      case ['a', 'b'].includes(value):
        additionalClass = classes.pinkAvatar;
        break;
      case ['c', 'd'].includes(value):
        additionalClass = classes.grapeAvatar;
        break;
      case ['e', 'f'].includes(value):
        additionalClass = classes.blueAvatar;
        break;
      case ['g', 'h'].includes(value):
        additionalClass = classes.greenAvatar;
        break;
      case ['i', 'j'].includes(value):
        additionalClass = classes.yellowAvatar;
        break;
      case ['k', 'l'].includes(value):
        additionalClass = classes.violetAvatar;
        break;
      case ['m', 'n'].includes(value):
        additionalClass = classes.indigoAvatar;
        break;
      case ['p', 'q', 'r'].includes(value):
        additionalClass = classes.cyanAvatar;
        break;
      case ['s', 't', 'u'].includes(value):
        additionalClass = classes.tealAvatar;
        break;
      case ['v', 'w', 'x'].includes(value):
        additionalClass = classes.limeAvatar;
        break;
      case ['y', 'z', 'o'].includes(value):
        additionalClass = classes.orangeAvatar;
        break;
      default:
        additionalClass = classes.limeAvatar;
        break;
    }

    return additionalClass;
  };

  return (
    <div className={classNames(classes.avatar, handleGetAvatarClass(letter.toLowerCase()))}>
      <span>{letter}</span>
    </div>
  );
};

export default Avatar;
