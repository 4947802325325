// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import Colors from '../styles/Color.styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.white,
    borderRadius: '16px',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0px',
    },
  },
  backDrop: {
    backgroundColor: 'rgba(33, 37, 46, 0.7)',
  },
  modalSubtitle: {
    fontWeight: 'normal',
    letterSpacing: '1px',
    fontSize: '16px',
    margin: '0px 0px 40px 0px',
    color: Colors.subtitleGrey,
    textAlign: 'center',
    lineHeight: '24px',
    fontFamily: 'Lato-Normal, sans-serif',
  },
  modalTitle: {
    fontSize: '32px',
    color: Colors.backgroundGrey,
    textAlign: 'center',
    fontFamily: 'Poppins-semibold, sans-serif',
    margin: (props) => props?.margin,
  },
  modalContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    height: (props) => props?.height,
    width: (props) => props?.width,
    padding: (props) => props?.padding,
    [theme.breakpoints.only('xs')]: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '@media (max-width: 350px)': {
      padding: '10px',
    },
  },
}));

export default useStyles;
