import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconButton, Menu, Badge } from '@material-ui/core';
import { Icon } from '@zaveit/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { initiateSocketConnection } from 'helpers/socket';
import EmptyView from './EmptyView';
import clock from '../../assets/clock.svg';
import useStyles, { StyledIconContainer } from './Notifications.styles';

dayjs.extend(relativeTime);

const Notifications = ({ iconColor, urlConfig }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ iconColor, open });
  const anchorRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);
  const unreadMsg = notifications?.filter((item) => !item.read);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current?.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const dateFormat = (date) => {
    const timeNow = dayjs(new Date());
    const diff = Math.abs(timeNow.diff(dayjs(date), 'second'));
    const oneHourToSec = 3600;
    const oneDayToSec = 86400;

    switch (true) {
      case diff < 60:
        return 'Now';
      case diff < oneHourToSec:
        return dayjs().from(dayjs(date), true);
      case diff < oneDayToSec:
        return dayjs().from(dayjs(date), true);
      case diff < oneDayToSec * 7:
        return dayjs().from(dayjs(date), true);
      default:
        return dayjs(date).format('DD MMM YYYY');
    }
  };

  const handleReadAll = (event) => {
    event.stopPropagation();
    const ids = unreadMsg.map((item) => item?.id);
    if (socket) {
      socket.emit('notification:all_markAsRead', { ids }, (resp) => {
        if (resp.statusCode === 200) {
          socket.emit('notification:list', {}, ({ data }) => setNotifications(data?.data));
        }
      });
    }
  };

  const handleReadNotification = (event, notification) => {
    event.stopPropagation();

    if (socket) {
      socket.emit('notification:markAsRead', { id: notification.id }, (resp) => {
        if (resp.statusCode === 200) {
          socket.emit('notification:list', {}, ({ data }) => setNotifications(data?.data));
        }
        window.open(`${window.location.origin}${notification?.url}`, '_blank');
      });
    }
  };

  useEffect(() => {
    const socketIo = initiateSocketConnection(urlConfig);
    setSocket(socketIo);
    if (socketIo) {
      socketIo.emit('notification:list', {}, ({ data }) => setNotifications(data?.data));

      socketIo.on('notification:add_list', (data) => setNotifications(data));

      socketIo.on('notification:add', (message) =>
        setNotifications((prevState) => [message, ...prevState]),
      );
    }
  }, []);

  return (
    <div className={classes.containerRoot}>
      <IconButton
        ref={anchorRef}
        aria-label="Notifications"
        onClick={handleToggle}
        classes={{
          root: classes.notificationBtn,
        }}
      >
        <Badge badgeContent={unreadMsg?.length} classes={{ badge: classes.badge }} color="default">
          <Icon iconName="notifications" />
        </Badge>
      </IconButton>
      <Menu
        disableScrollLock
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        elevation={0}
        PopoverClasses={{
          paper: classes.popoverPaper,
          root: classes.popoverRoot,
        }}
        id="menu-appbar"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 60,
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <div className={classes.menuContent}>
          {!notifications?.length ? (
            <EmptyView />
          ) : (
            <>
              <div className={classes.notifHeader}>
                <div>
                  <div className={classes.headerTitle}>Notifications</div>
                  <div className={classes.headerSubtitle}>
                    You have {unreadMsg?.length} unread messages
                  </div>
                </div>
              </div>
              <div className={classes.notifSubHeader}>
                <div className={classes.notifSubHeaderTitle}>Latest</div>
                {Boolean(unreadMsg?.length) && (
                  <button
                    type="button"
                    className={classes.readAllBtn}
                    disabled={!Boolean(unreadMsg?.length)}
                    onClick={handleReadAll}
                  >
                    Mark all as read
                  </button>
                )}
              </div>
              <div className={classes.msgContainer}>
                {notifications.map((item) => (
                  <div
                    className={classNames({
                      [classes.itemRow]: true,
                      [classes.itemRowUnread]: !item.read,
                    })}
                    role="button"
                    aria-hidden="true"
                    onClick={(event) => handleReadNotification(event, item)}
                    key={item.id}
                  >
                    {item?.icon && (
                      <StyledIconContainer color={item.color}>
                        <Icon iconName={item?.icon} />
                      </StyledIconContainer>
                    )}
                    <div>
                      <div className={classes.text}>{item?.text}</div>
                      <div className={classes.time}>
                        <img src={clock} alt="" />
                        <span>{dateFormat(item?.created)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Menu>
    </div>
  );
};

export default Notifications;
