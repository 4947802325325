export const saveData = (key, value) => {
  const date = Date.now();
  const expires = new Date(date + 1000 * 60 * 60 * 10);
  const storedObject = {
    expiresAt: expires,
    data: value,
  };
  localStorage.setItem(key, JSON.stringify(storedObject));
};

export const getData = (key) => {
  const savedValue = localStorage.getItem(key);
  const value = savedValue ? JSON.parse(savedValue) : null;
  if (value) {
    const expirationDate = new Date(value.expiresAt);
    if (expirationDate > new Date()) {
      return value?.data || value;
    }
    localStorage.removeItem(key);
  }
  return null;
};

export const removeData = (keys) => {
  keys.forEach((key) => localStorage.removeItem(key));
};
