// @ts-nocheck
import React from 'react';

export const AvatarIcon = ({ fill }) => (
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="AccountCircleIcon"
    fill={fill}
    width={24}
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" />
  </svg>
);

export const RightMenuIcon = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33268 16.6668C2.87435 16.6668 2.48213 16.5038 2.15602 16.1777C1.82935 15.851 1.66602 15.4585 1.66602 15.0002V5.00016C1.66602 4.54183 1.82935 4.14961 2.15602 3.8235C2.48213 3.49683 2.87435 3.3335 3.33268 3.3335H16.666C17.1243 3.3335 17.5168 3.49683 17.8435 3.8235C18.1696 4.14961 18.3327 4.54183 18.3327 5.00016V15.0002C18.3327 15.4585 18.1696 15.851 17.8435 16.1777C17.5168 16.5038 17.1243 16.6668 16.666 16.6668H3.33268ZM14.5827 7.22933H16.666V5.00016H14.5827V7.22933ZM14.5827 11.1043H16.666V8.896H14.5827V11.1043ZM3.33268 15.0002H12.916V5.00016H3.33268V15.0002ZM14.5827 15.0002H16.666V12.771H14.5827V15.0002Z"
      fill={fill}
    />
  </svg>
);
