import React, { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Tabs,
  Tab,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Icon } from '@zaveit/icons';

import Avatar from './ Avatar';
import useStyles from './RightSideBar.styles';

enum SideBarContentType {
  LIST = 'list',
  ACTION = 'action',
  EXPANDABLE_CARD = 'expandable-card',
}

const RightSideBar = ({ data, isRightSidebarOpened, onChangeTab = null }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [expanded, setEpanded] = useState([]);

  const handleChangeTab = (event, mode) => {
    setTab(mode);
    onChangeTab?.(mode);
  };

  useEffect(() => {
    if (data?.activeTab) {
      setTab(data?.activeTab);
    }
  }, [data?.activeTab]);

  useEffect(() => {
    let items = [...expanded];
    data?.contentData?.forEach((item) => {
      return item?.content?.forEach((content) => {
        if (
          content.type === SideBarContentType.EXPANDABLE_CARD &&
          content?.isExpanded &&
          !expanded.includes(content.title)
        ) {
          items = [...items, content.title];
        }
      });
    });
    setEpanded(items);

    if (!isRightSidebarOpened) {
      setEpanded([]);
    }
  }, [data, isRightSidebarOpened]);

  const handleAccordionChange = (accordion) => {
    let newExpanded = [...expanded];
    if (expanded.includes(accordion)) {
      newExpanded = newExpanded.filter((item) => item !== accordion);
    } else {
      newExpanded = [...newExpanded, accordion];
    }
    setEpanded(newExpanded);
  };

  return (
    <div className={`${classes.sidebar} ${!isRightSidebarOpened && classes.sidebarClose}`}>
      <div className={classes.contentContainer}>
        <div className={classes.tabsContainer}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            classes={{
              indicator: classes.indicator,
            }}
          >
            {data?.contentData &&
              data?.contentData?.map((item) => {
                return (
                  <Tab
                    label={item?.tab}
                    key={item.tab}
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                  />
                );
              })}
          </Tabs>
        </div>
        {data?.contentData &&
          data?.contentData?.map((item, index) => {
            return (
              <Fragment key={index}>
                {index === tab && (
                  <div>
                    {item.showSearch && (
                      <div className={classes.containerField}>
                        <TextField
                          fullWidth
                          key="random1"
                          variant="outlined"
                          label=""
                          placeholder="Search"
                          autoFocus
                          onChange={(e) => {
                            item.setSearchString(e.target.value);
                          }}
                          value={item.searchString}
                          className={classes.textField}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              notchedOutline: classes.notchedOutline,
                            },
                            type: 'text',
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  <Icon iconName="search" className={classes.searchIcon} />
                                </InputAdornment>
                              </>
                            ),
                          }}
                        />
                      </div>
                    )}
                    <div className={classes.contentInnerContainer}>
                      {item.content.map((content) => {
                        if (content.type === SideBarContentType.ACTION) {
                          return (
                            <div
                              className={classes.actionOption}
                              role="button"
                              aria-hidden="true"
                              onClick={content.onClick}
                              key={content?.title}
                            >
                              <Icon iconName={content?.iconName} className={classes.icon} />
                              <span>{content?.title}</span>
                            </div>
                          );
                        }

                        if (content.type === SideBarContentType.LIST) {
                          const isSelected =
                            item?.selectedOption &&
                            (content?.id === item?.selectedOption?.id ||
                              item?.selectedOption?.tenant === content?.tenant);

                          return (
                            <Tooltip
                              title={content?.tooltipTitle}
                              key={content?.id || content?.tenant}
                              enterDelay={500}
                              enterNextDelay={500}
                            >
                              <div
                                role="button"
                                aria-hidden="true"
                                onClick={content.onClick}
                                className={classNames({
                                  [classes.actionOption]: true,
                                  [classes.actionOptionSelected]: isSelected,
                                })}
                              >
                                <Avatar letter={content?.name.charAt(0)} />
                                <span className={classes.actionTitle}>{content?.name}</span>
                              </div>
                            </Tooltip>
                          );
                        }

                        if (content.type === SideBarContentType.EXPANDABLE_CARD) {
                          return (
                            <Accordion
                              classes={{ root: classes.accordion }}
                              elevation={0}
                              key={content?.title}
                              expanded={expanded.includes(content?.title)}
                              onChange={() => handleAccordionChange(content?.title)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className={classes.accordionTitle}>{content?.title}</div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className={classes.accordionDescription}>
                                  {content?.description}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                        return null;
                      })}
                    </div>
                    {item?.bottomActions && (
                      <div className={classes.bottomNav}>
                        {item.bottomActions.map((action) => (
                          <button
                            type="button"
                            onClick={action?.onClick}
                            className={classes[action.className]}
                            disabled={action?.disabled}
                            key={action.title}
                          >
                            {action.title}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default RightSideBar;
