import * as React from 'react';
import './styles.scss';

interface IconProps {
  iconName: string;
  className?: string;
  classType?: string;
}

const Icon = ({ iconName = '', className = '', classType }: IconProps) => {
  return <span className={`${classType} ${className}`}>{iconName}</span>;
};

Icon.defaultProps = {
  className: '',
  classType: 'material-icons-outlined',
};

export default Icon;
