import { makeStyles, styled } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

type Props = {
  iconColor?: string;
  open?: boolean;
};

export const StyledIconContainer = styled('div')(({ color }: { color: string }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color,
  backgroundColor: lighten(color, 0.8),
  width: '40px',
  height: '40px',
  minWidth: '40px',
  borderRadius: '40px',
  fontSize: '22px',
}));

const styles = makeStyles(() => ({
  menuContent: {
    width: '360px',
    minHeight: '172px',
  },
  msgContainer: {
    maxHeight: '70vh',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      borderRadius: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#DCE0E8',
      borderRadius: '8px',
    },
  },
  containerRoot: {
    margin: '0 8px',
  },
  notificationBtn: {
    color: ({ iconColor }: Props) => (iconColor !== '#fff' ? iconColor : '#fff'),
    fontSize: '24px',
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '32px',
    backgroundColor: ({ open }: Props) => (open ? 'rgba(148, 157, 176, 0.16)' : 'transparent'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
  },
  popoverPaper: {
    top: '55px !important',
    overflow: 'visible',
    width: '360px',
    padding: '10px 0px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow:
      '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 6,
      right: 12,
      width: 22,
      height: 18,
      borderRadius: '2px',
      backgroundColor: '#fff',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  popoverRoot: {},
  badge: {
    backgroundColor: '#FF4842',
    width: '20px',
    height: '20px',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '12px',
    marginLeft: '-5px',
    right: '3px',
    color: '#FFF',
  },
  placeholderContainer: {
    margin: 'auto',
    maxWidth: '280px',
    height: '172px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  placeholderIconContainer: {
    marginBottom: '24px',
    width: '56px',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9px',
    backgroundColor: 'rgba(148, 157, 176, 0.16)',
  },
  placeholderIcon: {
    fontSize: '30px',
    color: '#949DB0',
  },
  placeholderDescription: {
    marginTop: '8px',
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#687285',
    whiteSpace: 'pre-line',
  },
  notifHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px -1px 0px rgba(148, 157, 176, 0.24)',
    padding: '17px 20px',
  },
  headerTitle: {
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#303642',
  },
  headerSubtitle: {
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#687285',
  },
  notifSubHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px -1px 0px rgba(148, 157, 176, 0.24)',
    padding: '8px 20px',
  },
  notifSubHeaderTitle: {
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#687285',
    textTransform: 'uppercase',
  },
  readAllBtn: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '13px',
    lineHeight: '22px',
    color: '#687285',
    '&:hover': {
      color: '#303642',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#687285',
    },
  },
  image: {
    width: '40px',
    height: '40px',
  },
  itemRow: {
    display: 'flex',
    columnGap: '16px',
    padding: '12px 20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(145, 158, 171, 0.08)',
    },
  },
  itemRowUnread: {
    backgroundColor: 'rgba(148, 157, 176, 0.16)',
    marginBottom: '1px',
  },
  text: {
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#303642',
  },
  time: {
    color: '#949DB0',
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '4px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));

export default styles;
