import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  containerRoot: {
    margin: '0 8px',
  },
  notificationBtn: {
    padding: '6px',
    cursor: 'pointer',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
  },
  popoverPaper: {
    top: '55px !important',
    overflow: 'visible',
    width: '360px',
    padding: '10px 0px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow:
      '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 6,
      right: 12,
      width: 22,
      height: 18,
      borderRadius: '2px',
      backgroundColor: '#fff',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  popoverRoot: {},
  menuContent: {
    width: '360px',
    minHeight: '172px',
    padding: '24px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  title: {
    color: '#7DEC6B',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '12px',
    lineHeight: '20px',
    backgroundColor: 'rgba(95, 187, 80, 0.16)',
    padding: '1px 8px',
    borderRadius: '6px',
    textTransform: 'uppercase',
  },
  menuTitle: {
    color: '#303642',
    fontFamily: 'Poppins-semibold, sans-serif',
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '24px',
  },
  menuDescr: {
    color: '#303642',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
    marginBottom: '12px',
  },
}));

export default styles;
