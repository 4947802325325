// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import Colors from '../styles/Color.styles';

const useStyles = makeStyles(() => ({
  contentContainer: {
    fontFamily: 'Lato-Normal, sans-serif',
    boxSizing: 'border-box',
    width: '1080px',
    height: '90ch',
    maxHeight: '820px',
    overflow: 'auto',
    maxWidth: '100%',
    padding: '40px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  contentContainerPreview: {
    paddingTop: '124px',
  },
  bottomNav: {
    width: '100%',
    boxSizing: 'border-box',
    paddingBottom: '24px',
    backgroundColor: Colors.white,
  },
  checkboxRoot: {
    color: (props: Props) => `${props?.primaryColor} !important`,
    padding: '0px',
    marginRight: '11px',
  },
  checked: {
    color: (props: Props) => `${props?.primaryColor} !important`,
  },
  checked: {},
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    backgroundColor: Colors.white,
    padding: '40px 0px',
    marginBottom: '-40px',
    width: '100%',
    bottom: '-40px',
    boxSizing: 'border-box',
    justifySelf: 'flex-end',
    marginTop: 'auto',
  },
  checkBoxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '24px',
    rowGap: '24px',
  },
  checkbox: {
    cursor: 'pointer',
  },
  checkboxPreview: {
    color: Colors.disabledText,
    cursor: 'not-allowed',
  },
  title: {
    color: Colors.backgroundGrey,
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: 'Poppins-semibold, sans-serif',
    fontWeight: 600,
  },
  previewHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    boxSizing: 'border-box',
    height: '84px',
    backgroundColor: Colors.lightestGrey,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
  },
  previewTitle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins-semibold, sans-serif',
    color: Colors.backgroundGrey,
    fontSize: '18px',
    lineHeight: '28px',
  },
  button: {
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  editBtn: {
    color: (props: any) => props?.primaryColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '27px',
    '&:hover': {
      color: Colors.hoverDarkGreen,
    },
  },
  editIcon: {
    marginRight: '8px',
  },
  closeBtn: {
    marginBottom: '10px',
    fontSize: '16px',
    '&:hover': {
      color: Colors.disabledText,
    },
  },
  contentBody: {},
  bodyItem: {
    fontFamily: 'Lato-Normal, sans-serif',
    marginTop: '24px',
    borderBottom: 'solid 1px rgba(148, 157, 176, 0.24)',
    paddingBottom: '16px',
    '&:first-child': {
      marginTop: '0px',
    },
    '&:last-child': {
      paddingBottom: '40px',
      borderBottom: 'none',
    },
  },
  bigTetx: {
    fontWeight: 'bold',
    marginLeft: '5px',
  },
}));

export default useStyles;
