import React, { useState, useRef, useReducer, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDebounce, useSearchParam } from 'react-use';
import jwt_decode from 'jwt-decode';
import { CircularProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { AgreementModal } from '@zaveit/agreement';
import * as Yup from 'yup';
import axios from 'axios';
import queryString from 'query-string';
import ReactCodeInput from 'react-verification-code-input';
import { Button as UIKitButton } from '@zaveit/uikit';

import defaultConfig from '../config';
import { verifyUser, loginUser } from '../api-client/auth';
import { getEndUserAgreement, saveSignedAgreement } from '../api-client/agreement';
import { saveData, getData } from '../helpers/localStorageHelper';
import logoSvg from '../assets/logo.svg';
import microsoftLogo from '../assets/microsoft_logo.svg';

import Button from './Button';
import TextField from './TextField';
import Modal from './Modal';
import Verification from './Verification';
import useStyles from './Login.styles';
import { ModalTypes, StepsEnum } from './constants';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Field is required'),
});

export type TLogin = {
  loginTitle?: string;
  loginSubtitle?: string;
  emailCodeTitle?: string;
  smsCodeTitle?: string;
  emailCodeSubtitle?: string;
  smsCodeSubtitle?: string;
  handleSetSession?: (session: boolean) => void;
  handleLogout?: () => void;
  handleSetIsLoginFormOpen?: (isOpen: boolean) => void;
  logo?: string;
  open: boolean;
  sso?: boolean;
  urlConfig?: {
    [key: string]: string;
  };
};

function LoginForm({
  loginTitle,
  loginSubtitle,
  emailCodeTitle,
  smsCodeTitle,
  emailCodeSubtitle,
  smsCodeSubtitle,
  handleSetSession,
  handleSetIsLoginFormOpen,
  logo,
  handleLogout,
  open,
  sso,
  urlConfig,
}: TLogin) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [verificationError, setVerificationError] = useState<string>('');
  const [{ emailCode, smsCode, count, total }, setState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      emailCode: null,
      smsCode: null,
    },
  );

  const [email, setEmail] = useState('');
  const [step, setStep] = useState('username');
  const emailQS = useSearchParam('email');
  const stepQS = useSearchParam('step');

  const [loading, setLoading] = useState(false);
  const [loadingAcceptAgreement, setLoadingAcceptAgreement] = useState(false);
  const inputRef = useRef<ReactCodeInput>(null);
  const [errorMsg, setError] = useState('');
  const [modalData, setModalData] = useState(null);
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [ssoError, setSsoError] = useState('');

  // restore email and step
  useEffect(() => {
    if (!email && emailQS) {
      setEmail(emailQS);
    }
    if (step && stepQS && step !== stepQS) {
      setStep(stepQS);
    }
  }, [email, emailQS, step, stepQS]);

  // add email and step to query string
  useDebounce(
    () => {
      if (open) {
        const query = new URLSearchParams(location.search);

        if (!query.has('email') && email) {
          query.set('email', email);
        }

        if (!query.has('step') && step && email) {
          query.set('step', step);
        }

        const search = `?${query.toString()}`;

        if (search !== location.search) {
          history.replace({
            pathname: location.pathname,
            search,
            state: location.state,
          });
        }
      }
    },
    10,
    [email, history, location.pathname, location.search, location.state, open, step],
  );

  const verificationTitle = useMemo(
    () => (step === StepsEnum.sms ? smsCodeTitle : emailCodeTitle),
    [step, emailCodeTitle, smsCodeTitle],
  );
  const verificationSubtitle = useMemo(
    () => (step === StepsEnum.sms ? smsCodeSubtitle : emailCodeSubtitle),
    [step, emailCodeSubtitle, smsCodeSubtitle],
  );

  useEffect(() => {
    const { code } = queryString.parse(window.location.search);
    if (code && Number(code) === 403) {
      setSsoError('Your domain is not allowed to be registered');
    }
  }, []);

  const resetVerificationCodes = () => {
    inputRef?.current?.__clearvalues__();
  };

  const handleFocusInput = () => {
    (inputRef.current as any).iRefs[0].current.focus();
  };

  const handleGetAgreementData = async () => {
    setLoadingAcceptAgreement(true);
    const agreementModal = await getEndUserAgreement(urlConfig);
    setModalData(agreementModal);
    setLoadingAcceptAgreement(false);
  };

  const handleBack = () => {
    setEmail('');
    setStep('username');
    setVerificationError('');
    setState({
      emailCode: null,
      smsCode: null,
    });
  };

  useEffect(() => {
    const showAgreementModal = getData('showAgreementModal');
    const info = getData('userInfo');
    setUserInfo(info);
    setAgreementModalOpen(showAgreementModal);

    if (showAgreementModal) {
      handleGetAgreementData();
    }
  }, []);

  const handleSendVerificationCodes = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        username: email,
        code: step === 'mail' ? Number(emailCode) : Number(smsCode),
      };
      const response = await verifyUser(data, urlConfig);
      setStep(response?.data?.data?.type);
      setState({
        count: response?.data?.data?.count,
        total: response?.data?.data?.total,
      });
      if (response.data.sessiontoken) {
        setState({
          emailCode: null,
          smsCode: null,
        });
        setEmail('');
        setStep('username');
        handleSetIsLoginFormOpen(false);
        setVerificationError('');
        handleSetSession(true);
        const info = jwt_decode(response.data.sessiontoken);
        setUserInfo(info);
        saveData('userInfo', info);
        const agreementModal = await getEndUserAgreement(urlConfig);
        if (agreementModal?.length) {
          saveData('showAgreementModal', true);
          setModalData(agreementModal);
          setAgreementModalOpen(true);
        }
      }
      setLoading(false);
      resetVerificationCodes();
    } catch (error) {
      handleSetSession(false);
      handleSetIsLoginFormOpen(true);
      resetVerificationCodes();
      const message = error.response?.data?.errors?.tokesns || 'Invalid tokens.Try to verify again';
      setVerificationError(message);
      setLoading(false);
      console.error(error.response);
    }
  };

  const handleResendCode = async () => {
    resetVerificationCodes();
    setVerificationError('');
    try {
      setState({
        emailCode: null,
      });
      await loginUser(email, urlConfig);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeclineAgreement = () => {
    setStep('username');
    setState({
      emailCode: null,
      smsCode: null,
    });
    setEmail('');
    handleLogout();
    setAgreementModalOpen(false);
    saveData('showAgreementModal', false);
  };

  const handleAcceptAgreement = async () => {
    try {
      const agreements = modalData?.map((item) => item._id);
      const data = {
        user_id: userInfo?.uuid,
        tenant: userInfo.tenant,
        username: userInfo.username,
        full_name: `${userInfo.firstname} ${userInfo.lastname}`,
        browser: '',
        ip: '',
        data: agreements,
      };
      await saveSignedAgreement(data, urlConfig);
      setAgreementModalOpen(false);
      handleSetIsLoginFormOpen(false);
      saveData('showAgreementModal', false);
    } catch (err) {
      handleLogout();
      handleSetIsLoginFormOpen(true);
    }
  };

  const handleMicrosoft = async () => {
    const link = (
      await axios.get(`${urlConfig.baseAuthUrl}/v1/auth/sso/`, {
        headers: {
          'Origin-Url': window.location.href,
        },
      })
    ).data;
    window.location.href = link;
  };

  return (
    <>
      <Modal open={open}>
        {step === 'username' && !email && (
          <>
            <div className={classes.logoContainer}>
              <img src={logo} alt="logo" className={classes.logo} />
            </div>
            <div className={classes.loginContainer}>
              <div className={classes.modalTitle}>{loginTitle}</div>
              <div className={classes.modalSubtitle}>{loginSubtitle}</div>
              <Formik
                enableReinitialize
                initialValues={{
                  email: '',
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values) => {
                  setLoading(true);
                  try {
                    const responseStep = await loginUser(values.email, urlConfig);
                    setEmail(values.email);
                    setStep(responseStep?.data?.data?.type);
                    setState({
                      count: responseStep?.data?.data?.count,
                      total: responseStep?.data?.data?.total,
                    });
                    setError('');
                    handleFocusInput();
                    setLoading(false);
                  } catch (error) {
                    setError('Something went wrong. Please try again!');
                    setLoading(false);
                    console.error(error);
                  }
                }}
              >
                {({ errors, touched, isValid, dirty, handleChange }) => (
                  <Form>
                    <Field name="email">
                      {({ field }) => (
                        <TextField
                          onChange={handleChange}
                          field={field}
                          label="Email"
                          errors={errors && errors[field.name]}
                          touched={touched && touched[field.name]}
                        />
                      )}
                    </Field>
                    {errorMsg && <div className={classes.error}>{errorMsg}</div>}

                    <div className={classes.buttonWrapper}>
                      <UIKitButton
                        disabled={(Boolean(errors) && !isValid) || !dirty}
                        styleClass="black"
                        width="100%"
                        height="48px"
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress size={28} classes={{ root: classes.loader }} />
                        ) : (
                          'Login'
                        )}
                      </UIKitButton>
                    </div>

                    {sso && (
                      <>
                        <div className={classes.divider}>or continue with</div>

                        <Button
                          type="button"
                          onClick={handleMicrosoft}
                          className={classes.microsoftButton}
                        >
                          <img src={microsoftLogo} alt="microsoft logo" />
                          Microsoft
                        </Button>
                        <div className={classes.errorContainer}>
                          {ssoError && <div className={classes.error}>{ssoError}</div>}
                        </div>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}

        {email && (
          <Verification
            step={step}
            count={count}
            total={total}
            hasCode={!!(emailCode || smsCode)}
            isLoading={loading}
            verificationTitle={verificationTitle}
            verificationSubtitle={verificationSubtitle}
            verificationError={verificationError}
            inputRef={inputRef}
            handleBack={handleBack}
            handleChangeLoginState={setState}
            handleSendVerificationCodes={handleSendVerificationCodes}
            handleResendCode={handleResendCode}
          />
        )}
      </Modal>
      {modalData && (
        <AgreementModal
          type={ModalTypes.END_USER}
          open={agreementModalOpen}
          handleDecline={handleDeclineAgreement}
          handleAcept={handleAcceptAgreement}
          data={modalData}
          loading={loadingAcceptAgreement}
        />
      )}
    </>
  );
}

LoginForm.defaultProps = {
  loginTitle: 'Verify your identity',
  loginSubtitle: 'Please enter your email',
  emailCodeTitle: 'Verify your email',
  smsCodeTitle: 'Verify your phone',
  emailCodeSubtitle: 'Please verify your identity by entering the code we just sent to your email',
  smsCodeSubtitle:
    'Please verify your identity by entering the code we just sent you in a text message',
  handleSetSession: () => {},
  handleSetIsLoginFormOpen: () => {},
  handleLogout: () => {},
  logo: logoSvg,
  sso: false,
  urlConfig: defaultConfig,
};

export default LoginForm;
