import React, { createContext, useContext, useEffect, useState } from 'react';

import { IFeature, IUserInfo, FeatureContextProps, IFeatureContext } from './types';

const defaultContext: IFeatureContext = {
  features: [],
  session: false,
  loaded: false,
};

const FeatureContext = createContext(defaultContext);

export const FeatureContextProvider: React.FC<React.PropsWithChildren<FeatureContextProps>> = ({
  children,
  session,
  userFeatures,
}) => {
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (session) {
      const loadedFeatures = (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.data
        ?.features;
      const savedFeatures = userFeatures ?? loadedFeatures ?? null;
      setFeatures(userFeatures ?? loadedFeatures ?? []);
      setLoaded(Boolean(savedFeatures));
    } else {
      setFeatures([]);
    }
  }, [session, userFeatures]);

  return (
    <FeatureContext.Provider value={{ features, session, loaded }}>
      {children}
    </FeatureContext.Provider>
  );
};

export default () => useContext(FeatureContext);
