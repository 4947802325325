import React, { useRef, useState, useEffect } from 'react';
import { IconButton, Menu } from '@material-ui/core';
import { getStatus } from 'api-client/health';

import { AvatarIcon } from './Icons';
import useStyles from './AvatarMenu.styles';

const AvatarMenu = ({
  handleLogout,
  avatarColor,
  iconColor,
  userInfo = {
    firstname: '',
    lastname: '',
    username: '',
    msp: false,
    role: '',
    account: { name: '' },
    titles: {
      userRole: '',
    },
  },
  showGettingStarted = false,
  handleGettingStartedClick,
  urlConfig,
  companyName,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ avatarColor, iconColor, open });
  const anchorRef = useRef(null);
  const [statusData, setStatusData] = useState(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current?.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const handleRedirect = (path) => (window.location.href = `${window.location.origin}${path}`);

  const handleGetData = async () => {
    try {
      const { data } = await getStatus(urlConfig);
      setStatusData(data);
    } catch (err) {
      console.error(err);
      setStatusData(null);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className={classes.avatarContainerRoot}>
      <IconButton
        ref={anchorRef}
        aria-label="Account"
        data-testid="test-accountcircle"
        onClick={handleToggle}
        classes={{
          root: classes.account,
        }}
      >
        <div className={classes.menuButton}>
          <AvatarIcon fill={iconColor} />
        </div>
      </IconButton>
      <Menu
        disableScrollLock
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        elevation={0}
        PopoverClasses={{
          paper: classes.popoverPaper,
          root: classes.popoverRoot,
        }}
        id="menu-appbar"
        data-testid="menu-appbar"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 60,
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {userInfo?.firstname || userInfo?.lastname || userInfo?.username ? (
          <div className={classes.usernameCont}>
            <div className={classes.nameContainer}>
              <div>{`${userInfo?.firstname} ${userInfo?.lastname}`}</div>
              {userInfo?.msp && <div className={classes.mspBadge}>MSP</div>}
            </div>
            <div className={classes.menuSubtitle}>{userInfo?.titles?.userRole}</div>
            <div className={classes.menuSubtitle}>{companyName || userInfo?.account?.name}</div>
          </div>
        ) : null}
        <div
          onClick={() => handleRedirect('/account/settings')}
          className={classes.menuLink}
          role="button"
          aria-hidden="true"
          data-testid="account-settings-btn"
        >
          <div className={classes.linkItem}>Account settings</div>
        </div>
        {!showGettingStarted && handleGettingStartedClick && (
          <div
            onClick={handleGettingStartedClick}
            className={classes.menuLink}
            role="button"
            aria-hidden="true"
            data-testid="getting-started-btn"
          >
            <div className={classes.linkItem}>Getting Started</div>
          </div>
        )}
        <div
          onClick={handleLogout}
          className={classes.menuLinkLogout}
          role="button"
          aria-hidden="true"
          data-testid="logout-btn"
        >
          <div className={classes.linkItem}>Logout</div>
        </div>
      </Menu>
    </div>
  );
};

export default AvatarMenu;
