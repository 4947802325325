import React, { Children, isValidElement, cloneElement } from 'react';

import useFeature from './useFeature';
import { FeatureProps, IDisabled } from './types';

const Feature: React.FC<React.PropsWithChildren<FeatureProps>> = ({
  feature: searchFeature,
  children,
}) => {
  const { enabled, feature } = useFeature(searchFeature);
  return enabled ? (
    <>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement<IDisabled>(child, { disabled: feature.disabled });
        }
        return null;
      })}
    </>
  ) : null;
};

export default Feature;
