import { useMemo } from 'react';
import useFeatureContext from './FeatureContext';
import { IFeature } from './types';

const NOT_FOUND = -1;

const useFeature = (value) => {
  const { features, session, loaded } = useFeatureContext();

  const featureIndex = useMemo(
    () =>
      features.findIndex((item: IFeature) => item.namespace?.toLowerCase() === value.toLowerCase()),
    [features, value],
  );

  return { enabled: featureIndex !== NOT_FOUND, feature: features[featureIndex], session, loaded };
};

export default useFeature;
