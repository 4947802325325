// @ts-nocheck
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  sidebar: {
    position: 'fixed',
    zIndex: 2,
    top: 65,
    right: 0,
    height: 'calc(100vh - 65px)',
    backgroundColor: '#fff',
    boxShadow: 'inset 1px 0px 0px rgba(145, 158, 171, 0.24);',
    transform: 'translate(0px, 0px)',
    transition: 'transform 0.2s',
  },
  contentInnerContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 300px)',
  },
  loader: {
    color: '#5fbb50',
  },
  loaderContainer: {
    marginTop: '80px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  sidebarClose: {
    transform: 'translate(300px, 0px)',
    transition: 'transform 0.2s',
  },
  wrapperContainer: {
    position: 'relative',
  },
  contentContainer: {
    width: '280px',
    height: 'fit-content',
    paddingBottom: '64px',
    boxSizing: 'border-box',
    padding: '0px 24px',
  },
  tabRoot: {
    textTransform: 'none',
    fontFamily: 'Lato-SemiBold, sans-serif',
    color: '#687285',
    lineHeight: '22px',
    opacity: 1,
    marginRight: '40px',
    padding: '0px',
    minWidth: 'auto',
    '&.MuiTab-labelIcon': {
      minHeight: '48px',
    },
    '& .MuiTab-wrapper': {
      padding: '0px',
      flexDirection: 'row',
      minWidth: 'auto',
      fontSize: '14px',
      '&>:first-child': {
        marginBottom: 0,
        marginRight: 10,
      },
    },
  },
  tabSelected: {
    textTransform: 'none',
    fontFamily: 'Lato-SemiBold, sans-serif',
    padding: '0px',
    marginRight: '40px',
    minWidth: 'auto',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#303642',
    opacity: 1,
  },
  tabsContainer: {
    marginBottom: '24px',
    width: '100%',
    '& .MuiTabs-root': {
      height: '48px',
    },
  },
  indicator: {
    backgroundColor: '#5FBB50',
  },
  icon: {
    color: '#5FBB50',
    fontSize: '18px',
    marginRight: '8px',
  },
  actionOption: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    height: '38px',
    color: '#303642',
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    cursor: 'pointer',
    borderRadius: '8px',
    paddingLeft: '19px',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.095)',
    },
  },
  actionOptionSelected: {
    backgroundColor: 'rgba(148, 157, 176, 0.08)',
  },
  actionTitle: {
    width: '170px',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  accordion: {
    marginBottom: '16px',
    border: '1px solid rgba(148, 157, 176, 0.32)',
    borderRadius: '8px !important',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
  },
  accordionTitle: {
    color: '#303642',
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  accordionDescription: {
    color: '#303642',
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '14px',
    lineHeight: '18px',
  },
  containerField: {
    marginBottom: '24px',
    position: 'relative',
    fontFamily: 'Lato-Normal, sans-serif',
    '& .MuiFormLabel-root.Mui-focused': {
      color: (props) => props?.textColor || '#000000',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Lato-Normal, sans-serif !important',
      '&::placeholder': {
        color: '#949DB0',
        opacity: 1,
      },
    },
    '& .MuiInputBase-root': {
      color: '#303642',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -8px) scale(0.75)',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '16px',
      backgroundColor: '#FFFFFF',
      color: '#949DB0',
      fontFamily: 'Lato-Normal, sans-serif',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      boxSizing: 'border-box',
      '& fieldset': {
        border: 'solid 1px #949DB0',
      },
      '&:hover fieldset': {
        border: 'solid 1px #949DB0',
      },
      '&.Mui-focused fieldset': {
        border: 'solid 1px #949DB0',
      },
    },
  },
  cssOutlinedInput: {
    height: '40px',
  },
  notchedOutline: {
    borderRadius: '8px',
  },
  textField: {
    width: '100%',
    outline: 'none',
  },
  searchIcon: {
    color: '#949DB0',
  },
  textField: {
    width: '100%',
    outline: 'none',
  },
  bottomNav: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: 'solid 1px rgba(148, 157, 176, 0.24)',
    borderLeft: 'solid 1px rgba(148, 157, 176, 0.24)',
    padding: '28px 0px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#FFF',
  },
  resetBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#5FBB50',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '13px',
    lineHeight: '22px',
    marginRight: '12px',
    '&:disabled': {
      color: '#303642',
      cursor: 'not-allowed',
    },
  },
  dismissBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#303642',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '13px',
    lineHeight: '22px',
    marginLeft: '12px',
  },
}));

export default styles;
