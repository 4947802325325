import * as React from 'react';

import useStyles from './Button.styles';

type TButton = {
  className?: string;
  children: any;
  onClick?: (values: any | undefined) => void | Promise<void>;
  disabled?: boolean;
  width?: string;
  marginTop?: string;
  height?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};
const Button = ({
  children,
  onClick,
  disabled,
  width,
  height,
  marginTop,
  type,
  className: customClassName,
}: TButton) => {
  const classes = useStyles({ width, marginTop, height });
  const className = customClassName ? `${classes.loginBtn} ${customClassName}` : classes.loginBtn;
  return (
    <button onClick={onClick} className={className} disabled={disabled} type={type}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
  width: '',
  marginTop: '',
  height: '',
  type: 'submit',
  className: '',
};

export default Button;
