import { makeStyles } from '@material-ui/core';
import Colors from '../styles/Color.styles';

const useStyles = makeStyles(() => ({
  loginBtn: {
    width: '100%',
    height: '48px',
    outline: 'none',
    border: 'none',
    backgroundColor: Colors.mainGreen,
    padding: 0,
    borderRadius: '8px',
    fontFamily: 'Lato-Black, sans-serif',
    fontSize: '14px',
    textTransform: 'none',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.hoverDarkGreen,
      transition: 'background-color 0.3s',
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
}));

export default useStyles;
