import React from 'react';
import { Icon } from '@zaveit/icons';
import { Badge, IconButton } from '@material-ui/core';

import useStyles from './HelpMenu.styles';

const HelpMenu = ({ route, color }) => {
  const classes = useStyles({ color });

  return (
    <a href={route} className={classes.link} rel="noreferrer" target="_blank">
      <IconButton
        aria-label="Help"
        classes={{
          root: classes.helpmenuBtn,
        }}
      >
        <Badge color="default">
          <Icon iconName="help" />
        </Badge>
      </IconButton>
    </a>
  );
};

export default HelpMenu;
