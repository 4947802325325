import { makeStyles } from '@material-ui/core';
import Colors from '../styles/Color.styles';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    position: 'relative',
    fontFamily: 'Lato-Normal, sans-serif',
    marginRight: '0px',
    '& .MuiFormLabel-root.Mui-focused': {
      color: Colors.mainGreen,
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Lato-Normal, sans-serif !important',
      color: Colors.darkest2Grey,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -5px) scale(0.75)',
    },
  },
  textField: {
    width: '100%',
    outline: 'none',
  },
  cssOutlinedInput: {
    height: '56px',
    '&$focused $notchedOutline': {
      border: `2px solid  ${Colors.mainGreen} !important`,
      color: `${Colors.darkest2Grey} !important`,
    },
    '&$error $notchedOutline': {
      border: `2px solid  ${Colors.error}`,
    },
  },
  focused: {},
  errorInput: {},
  notchedOutline: {
    border: `solid 1px ${Colors.lightGrey}`,
    borderRadius: '8px',
  },
  inputDisabled: {
    border: `solid 1px ${Colors.lightGrey}`,
  },
  errorField: {
    textAlign: 'left',
    padding: '10px 0px 0px 5px',
    fontSize: '14px',
    fontFamily: 'Lato-Normal, sans-serif',
    letterSpacing: '0.5px',
    color: Colors.red,
  },
  formTitle: {
    fontWeight: 600,
    fontSize: '20px',
    margin: '0px 0px 24px 0px',
    color: Colors.greyIcon,
  },
  formSubTitle: {
    color: Colors.subtitleGrey,
    fontSize: '16px',
    fontWeight: 600,
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'Lato-Normal, sans-serif',
    letterSpacing: '0.5px',
    color: Colors.red,
    margin: '6px 0px 30px 0px',
  },
  buttonWrapper: {
    marginTop: '30px',
  },
  codesContainer: {
    padding: '24px',
    width: '480px',
    boxSizing: 'border-box',
    [theme.breakpoints.only('xs')]: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '@media (max-width: 350px)': {
      padding: '10px',
    },
  },
  modalSubtitle: {
    fontWeight: 'normal',
    letterSpacing: '1px',
    fontSize: '16px',
    margin: '0px 0px 40px 0px',
    color: Colors.subtitleGrey,
    textAlign: 'center',
  },
  stepper: {
    position: 'relative',
    marginRight: '16px',
    display: 'flex',
  },
  stepperText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: Colors.subtitleGrey,

    '&, & .MuiTypography-body1': {
      fontFamily: 'Lato-Bold, sans-serif',
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  greenText: {
    color: Colors.darkGreyV2,
  },
  progressBar: {
    color: Colors.darkGreyV2,
  },
  fakeProgressBar: {
    position: 'absolute',
    color: Colors.borderLightGrey,
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '32px',
    margin: '8px 0px',
    color: Colors.backgroundGrey,
    textAlign: 'center',
  },
  verificationTitle: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.backgroundGrey,

    '& .MuiTypography-body1': {
      fontFamily: 'Poppins-semibold, sans-serif',
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  greenTitle: {
    marginRight: '5px',
    color: Colors.darkGreyV2,
  },
  verificationSubtitle: {
    margin: '4px auto 24px',
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.backgroundGrey,
  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  navigationContainer: {
    width: '100%',
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resendQuestionContainer: {
    display: 'flex',

    '& .MuiTypography-body1': {
      fontFamily: 'Lato-Normal, sans-serif',
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  resendQuestion: {
    marginRight: '4px',
    color: Colors.backgroundGrey,
  },
  resendBtn: {
    color: Colors.blueLink,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
      color: Colors.hoverDarkGreen,
    },
  },
  codeContainer: {
    display: 'flex',
    marginBottom: '24px',
    position: 'relative',
    '& input': {
      margin: '0px 8px',
      border: `solid 1px ${Colors.borderInputGrey} !important`,
      backgroundColor: 'transparent',
      color: Colors.backgroundGrey,
      fontSize: '28px',
      fontFamily: 'Lato-Normal, sans-serif',
      fontWeight: 600,
      borderRadius: '8px',
      caretColor: `${Colors.backgroundGrey} !important`,
      '&:first-child': {
        margin: '0px 8px 0px 0px',
      },
      '&:last-child': {
        margin: '0px 0px 0px 8px',
      },
      [theme.breakpoints.only('xs')]: {
        '&:first-child': {
          margin: '0px 4px 0px 0px',
        },
        '&:last-child': {
          margin: '0px 0px 0px 4px',
        },
        margin: '0px 4px',
        width: '40px !important',
        height: '55px !important',
      },
    },
    '& div': {
      width: '100% !important',
    },
  },
  buttonsContainer: {
    display: 'flex',
    '& > *:last-of-type': {
      marginLeft: '12px',
    },
  },
  errorVerification: {
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'Lato-Normal, sans-serif',
    letterSpacing: '0.5px',
    color: Colors.red,
    margin: '5px 0px 20px 0px',
  },
  closeIcon: {
    color: Colors.backgroundGrey,
    fontSize: '24px !important',
    fontWeight: 'bolder',
    position: 'absolute',
    right: '25px',
    top: '25px',
    cursor: 'pointer',
    '&:hover': {
      color: Colors.mainGrey,
    },
  },
  linkBtn: {
    color: Colors.mainGreen,
    fontWeight: 600,
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: Colors.hoverDarkGreen,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    color: Colors.white,
  },
  logo: {
    maxHeight: '50px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },

  loginContainer: {
    padding: '20px 40px 32px 40px',
    width: '500px',
    boxSizing: 'border-box',
    [theme.breakpoints.only('xs')]: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '@media (max-width: 350px)': {
      padding: '10px',
    },
  },

  divider: {
    marginTop: '24px',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',

    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.placeholderColor,

    '&::after, &::before': {
      content: '""',
      border: '1px solid rgba(148, 157, 176, 0.24)',
      flex: 1,
    },

    '&:not(:empty)::before': {
      marginRight: '16px',
    },

    '&:not(:empty)::after': {
      marginLeft: '16px',
    },
  },

  microsoftButton: {
    background: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',

    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '15px',
    lineHeight: '26px',
    color: Colors.backgroundGrey,

    '&:hover': {
      backgroundColor: Colors.lightBackgroundGrey,
      boxShadow: 'none',
    },
  },
  greenButton: {
    padding: '6px 16px',
    height: '36px',
    backgroundColor: Colors.mainGreen,
    fontFamily: 'Lato-Bold, sans-serf',
    fontSize: '14px',
    lineHeight: '24px',
    boxShadow: '0px 8px 16px rgba(95, 187, 80, 0.24)',
    boxSizing: 'border-box',

    '&:hover': {
      backgroundColor: Colors.hoverDarkGreen,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  whiteButton: {
    padding: '6px 16px',
    height: '36px',
    backgroundColor: Colors.white,
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-Bold, sans-serf',
    fontSize: '14px',
    border: `1px solid ${Colors.disabledGrey}`,
    marginTop: '0px',
    boxSizing: 'border-box',

    '&:hover': {
      backgroundColor: Colors.disabledGrey,
    },
    '&:disabled': {
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.backgroundGrey,
    maxWidth: '420px',
  },
  info: {
    color: Colors?.blueLink,
    fontSize: '20px',
  },
}));

export default useStyles;
