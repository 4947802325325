// @ts-nocheck
import React from 'react';

export const MenuIcon = ({ fill }) => (
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="MenuIcon"
    fill={fill}
    width={24}
  >
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);
