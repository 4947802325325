// @ts-nocheck
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 0px 0 25px',
    height: '65px',
    position: 'fixed',
    top: '0px',
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 2,
    backgroundColor: (props) => props?.colors?.header_background || '#FFFFFF',
    '& .MuiSvgIcon-fontSizeLarge': {
      width: '30px',
    },
    '@media (max-width: 960px)': {
      padding: '0px 20px',
    },
    '@media (max-width: 400px)': {
      padding: '0px 0px 0px 5px',
      '& .MuiSvgIcon-fontSizeLarge': {
        fontSize: '38px',
      },
    },
    '& .MuiPaper-elevation8': {
      boxShadow:
        '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
    },
  },
  menuButton: {
    marginRight: '16px',
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '32px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
  },
  menuButtonHidden: {
    width: '44px',
    height: '44px',
    marginRight: '16px',
    backgroundColor: 'transparent',
  },
  logoBtn: {
    color: 'rgb(255, 255, 255)',
    fontSize: '24px',
    width: '60px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
  },
  logo: {
    width: 'auto',
    cursor: 'pointer',
    maxHeight: '30px',
    maxWidth: '60px',
  },
  burgerMenuContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  burgerMenu: {
    width: '35px !important',
    height: '35px !important',
    color: (props) => props?.colors?.header_navigation || '#000000',
  },
  moreIconMenuBtn: {
    fontSize: '22px',
    color: (props) => props?.colors?.header_navigation || '#000000',
    border: 'none',
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '32px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  iconContainer: {
    display: 'none',
    '@media (max-width: 830px)': {
      display: 'block',
      marginRight: '10px',
    },
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '20px',
  },
  useMenuContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  defaultButton: {
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    backgroundColor: 'transparent',
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  menuIcon: {
    color: (props) => props?.colors?.header_navigation || '#000000',
    marginRight: '40px',
  },
  iconsBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuContent: {
    width: 300,
    height: '100%',
    backgroundColor: (props) => props?.colors?.header_background || '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '50px 0px',
    '@media (min-width: 830px)': {
      display: 'none',
    },
  },
  menuRoot: {
    '@media print': {
      display: 'none',
    },
    '@media (min-width: 830px)': {
      display: 'none',
    },
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '316px',
    '& #arrow-btn': {
      display: 'none',
    },
    '&:hover': {
      '& #arrow-btn': {
        display: 'flex',
      },
    },
  },
  menuContainerIconView: {
    width: '144px',
  },
  arrowBtnContainer: {
    position: 'absolute',
    right: '44px',
    top: '70px',
    borderRadius: '26px',
    backgroundColor: '#fff',
    zIndex: 2,
    width: '26px',
    height: '26px',
  },
  arrowBtn: {
    fontSize: '28px',
    borderRadius: '26px',
    width: '26px',
    height: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#949db03d',
    color: '#949DB0',
    boxShadow: '0px 1px 2px rgba(148, 157, 176, 0.24)',
    cursor: 'pointer',
  },
  arrowBtnfullView: {
    transform: 'rotate(180deg)',
  },
}));

export default styles;
