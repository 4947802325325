// @ts-nocheck
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  link: {
    textDecoration: 'none',

    '&:active': {
      outline: '0',
      textDecoration: 'none',
    },
  },
  helpmenuBtn: {
    color: (props) => props?.color || '#fff',
    fontSize: '24px',
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '32px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
  },
}));

export default styles;
