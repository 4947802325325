import React from 'react';

import SubItem from 'components/Menu/SubItem';
import useStyles from 'components/Menu/Menu.styles';

const MenuSubitems = ({
  item,
  subitem,
  selected,
  itemsOpen,
  showTitles,
  isTooltip = false,
  areSubitemsActive = undefined,
  toggleItem,
  setIsRightSidebarOpened,
  primaryColor = '',
}) => {
  const classes = useStyles({
    areSubitemsShown: itemsOpen.includes(subitem.title) || isTooltip,
    primaryColor,
  });

  let isSubitemActive = selected.includes(item?.title) && selected.includes(subitem?.title);
  let subitems;

  if (subitem?.items?.length) {
    subitems = subitem?.items?.map((newSubitem) => {
      areSubitemsActive = selected.includes(subitem?.title) && selected.includes(newSubitem?.title);
      return (
        <MenuSubitems
          item={subitem}
          subitem={newSubitem}
          selected={selected}
          itemsOpen={itemsOpen}
          showTitles={showTitles}
          isTooltip={isTooltip}
          key={newSubitem?.title}
          primaryColor={primaryColor}
          setIsRightSidebarOpened={setIsRightSidebarOpened}
          toggleItem={toggleItem}
          areSubitemsActive={areSubitemsActive}
        />
      );
    });
  }

  return (
    <div className={subitems && classes.subitemsContainer}>
      <SubItem
        subitem={subitem}
        itemsOpen={itemsOpen}
        isActive={isSubitemActive || areSubitemsActive}
        showTitles={showTitles}
        isTooltip={isTooltip}
        setIsRightSidebarOpened={setIsRightSidebarOpened}
        toggleItem={toggleItem}
        primaryColor={primaryColor}
      />
      {subitems}
    </div>
  );
};

export default MenuSubitems;
