/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect, useCallback } from 'react';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { CircularProgress, Tooltip } from '@material-ui/core';
import isEqual from 'lodash.isequal';
import classNames from 'classnames';
import { Icon } from '@zaveit/icons';
import format from '@zaveit/format';

import { saveData } from 'helpers/localStorageHelper';
import { SideBarModes } from 'components/constants';

import { getLatestHealth } from 'api-client/health';
import MenuSubitems from './MenuSubitems';
import useStyles from './Menu.styles';

const Label = ({ label }) => {
  const classes = useStyles({ labelColor: label?.color, labelBc: label?.backgroundColor });
  return <div className={classes.labelItem}>{label?.title}</div>;
};

const SwipeableTemporaryDrawer = ({
  menu,
  path,
  open,
  loading,
  sideBarMode,
  setIsRightSidebarOpened,
  setSideBarMode,
  colors,
  createHref,
  urlConfig,
}) => {
  const classes = useStyles({ primaryColor: colors?.primary_color });
  const [itemsOpen, setItemsOpen] = useState<string[]>([]);
  const [healthData, setHealthData] = useState(null);
  const [selected, setSelected] = useState<string[]>([]);

  const handleGetLatestHealth = async () => {
    try {
      const { data } = await getLatestHealth(urlConfig);
      setHealthData(data);
    } catch (err) {
      console.error(err);
      setHealthData(null);
    }
  };

  useEffect(() => {
    handleGetLatestHealth();
    // eslint-disable-next-line
  }, []);

  const toggleItem = useCallback(
    (id) => {
      if (!itemsOpen.includes(id)) {
        setItemsOpen([...itemsOpen, id]);
      } else {
        const filtered = itemsOpen.filter((value: string) => value !== id);
        setItemsOpen(filtered);
      }
    },
    [itemsOpen],
  );

  const handleSetSelected = useCallback(
    (item) => {
      item?.items?.forEach((subitem) => {
        if (path.startsWith(subitem?.url) || path.endsWith(subitem?.url)) {
          setItemsOpen((prev) => [...prev, item.title, subitem.title]);
          setSelected((prev) => {
            const updatedSelectedItems = [...prev, subitem.title];
            if (!prev?.includes(item?.title)) {
              return [item?.title, ...prev, subitem.title];
            }
            return updatedSelectedItems;
          });
        }
      });

      if (item?.items.length) {
        item?.items?.forEach((newItem) => handleSetSelected(newItem));
      }
    },
    [path],
  );

  useEffect(() => {
    const appPath = path.split('/')[1];
    if (menu && open) {
      menu.forEach((category) =>
        category?.items?.forEach((item) => {
          if (item?.url === path || item?.url === `/${appPath}`) {
            setSelected([item.title]);
            setItemsOpen([item?.title]);
          }

          if (item?.items?.length) {
            handleSetSelected(item);
          }
        }),
      );
    }
  }, [open, menu, path, handleSetSelected]);

  const Wrapper = useCallback(
    ({ href = null, children, ...props }) =>
      href ? (
        <a href={href} {...props}>
          {children}
        </a>
      ) : (
        <div {...props}>{children}</div>
      ),
    [],
  );

  const showTitles = sideBarMode === SideBarModes.FULL_VIEW;
  const getHref = useCallback(
    (item) => {
      if (typeof createHref === 'function') {
        return createHref(item);
      }

      return !item?.disabled && item.url ? `${window.location.origin}${item.url}` : '#';
    },
    [createHref],
  );

  const latestDeployDate = new Date(healthData?.deployed?.deployed);
  const latestVersion = healthData?.version?.version;

  return (
    <div
      className={classNames({
        [classes.sidebar]: true,
        [classes.sidebarClose]: !open,
      })}
    >
      <div
        className={classNames({
          [classes.contentContainer]: true,
          [classes.iconsView]: sideBarMode === SideBarModes.ICONS_VIEW,
        })}
      >
        {loading && !menu && (
          <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} />
          </div>
        )}
        {menu &&
          !loading &&
          menu?.map((category, index) => (
            <div key={category?.title}>
              {category?.title && (
                <div
                  className={classNames({
                    [classes.categoryTitle]: true,
                    [classes.categoryTitleTransparent]: !showTitles,
                  })}
                >
                  {category?.title}
                </div>
              )}
              {category?.items.map((item) => (
                <Tooltip
                  interactive
                  placement="right"
                  key={item?.title}
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    sideBarMode === SideBarModes.ICONS_VIEW ? (
                      <div>
                        <div
                          className={classNames({
                            [classes.tooltipTitle]: true,
                            [classes.tooltipTitleNoMargin]: !item?.items?.length,
                          })}
                        >
                          {item?.title}
                        </div>
                        {item?.items?.map((subitem) => (
                          <MenuSubitems
                            item={item}
                            subitem={subitem}
                            selected={selected}
                            itemsOpen={itemsOpen}
                            showTitles={true}
                            isTooltip={true}
                            key={subitem?.title}
                            setIsRightSidebarOpened={setIsRightSidebarOpened}
                            toggleItem={toggleItem}
                            primaryColor={colors?.primary_color}
                          />
                        ))}
                      </div>
                    ) : (
                      ''
                    )
                  }
                >
                  <div key={item?.title}>
                    <div className={classes.wrapperContainer}>
                      <Wrapper
                        className={classNames({
                          [classes.itemTitle]: true,
                          [classes.itemTitleActive]: selected[0] === item?.title,
                          [classes.itemTitleTransparent]: !showTitles,
                          [classes.itemDisabled]: item?.disabled,
                        })}
                        role="button"
                        aria-hidden="true"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item?.disabled) return;
                          if (!item?.url) toggleItem(item?.title);
                          setSideBarMode(SideBarModes.FULL_VIEW);
                          saveData('isRightSidebarOpened', false);
                        }}
                        href={getHref(item)}
                      >
                        <div
                          className={classNames({
                            [classes.titleContainer]: true,
                          })}
                        >
                          <Icon
                            iconName={item?.icon}
                            className={classNames({
                              [classes.icons]: true,
                              [classes.iconsBig]: sideBarMode === SideBarModes.ICONS_VIEW,
                            })}
                          />
                          {showTitles && (
                            <div className={classes.titleItemContainer}>
                              <span>{item?.title}</span>
                              {item?.label && <Label label={item?.label} />}
                            </div>
                          )}
                        </div>
                      </Wrapper>
                      {Boolean(item?.items?.length) && showTitles && (
                        <KeyboardArrowRightRoundedIcon
                          role="button"
                          aria-hidden="true"
                          onClick={() => {
                            toggleItem(item?.title);
                            setIsRightSidebarOpened(false);
                          }}
                          className={classNames({
                            [classes.iconArrow]: true,
                            [classes.iconArrowActive]: selected[0] === item?.title,
                            [classes.iconDown]: itemsOpen.includes(item?.title),
                          })}
                        />
                      )}
                    </div>
                    {itemsOpen.includes(item?.title) && item?.items?.length && showTitles ? (
                      <>
                        {item.items.map((subitem) => (
                          <MenuSubitems
                            item={item}
                            subitem={subitem}
                            selected={selected}
                            itemsOpen={itemsOpen}
                            showTitles={showTitles}
                            key={subitem?.title}
                            setIsRightSidebarOpened={setIsRightSidebarOpened}
                            primaryColor={colors?.primary_color}
                            toggleItem={toggleItem}
                          />
                        ))}
                      </>
                    ) : null}
                  </div>
                </Tooltip>
              ))}
              {index < menu?.length - 1 && <div className={classes.divider} />}
            </div>
          ))}
        <div className={classes.versionWrapper}>
          <Tooltip
            arrow
            title={`Platform version ${latestVersion} deployed at ${
              format.date(latestDeployDate).long
            }`}
          >
            <span className={classes.version}>
              {latestVersion} | {format.date(latestDeployDate).short}
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SwipeableTemporaryDrawer, isEqual);
