export default {
  mainPink: '#6C0A53',
  backgroundPink: '#FDD8D6',
  mainGrape: '#390B67',
  backgroundGrape: '#FDD8F9',
  backgroundBlue: '#D0F2FF',
  blueText: '#04297A',
  greenText: '#295022',
  lighterGreen: '#DCFFD7',
  yelowText: '#7A4F01',
  lighterYellow: '#FFF7CD',
  violetText: '#1D0E74',
  lighterViolet: '#E7DBFE',
  indigoText: '#0C1773',
  lighterIndigo: '#D8E3FE',
  cyanText: '#023762',
  lighterCyan: '#CDFCF3',
  tealText: '#034B53',
  lighterTeal: '#CDFADC',
  limeText: '#295F04',
  lighterLime: '#F0FCCF',
  orangeText: '#7A1801',
  lighterOrange: '#FFEDCD',
};
