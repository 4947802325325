import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import useFeature from './useFeature';
import { FeatureRouteProps } from './types';

const FeatureRoute: React.FC<FeatureRouteProps & RouteProps> = ({
  feature: searchFeature,
  fallbackCallback,
  ...routeProps
}) => {
  const { enabled, session, loaded } = useFeature(searchFeature);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!enabled && session && loaded && mounted) {
      if (fallbackCallback) {
        fallbackCallback();
      } else {
        window.location.href = `${window.location.origin}/dashboard`;
      }
    }
  }, [enabled, fallbackCallback, session, loaded, mounted]);

  return enabled ? <Route {...routeProps} /> : null;
};

export default FeatureRoute;
