import { darken, lighten, makeStyles } from '@material-ui/core';

type Props = {
  labelColor?: string;
  labelBc?: string;
  primaryColor?: string;
  areSubitemsShown?: boolean;
};

const styles = makeStyles(() => ({
  sidebar: {
    paddingTop: '36px',
    position: 'absolute',
    zIndex: 1,
    top: 66,
    left: 0,
    overflowY: 'auto',
    height: 'calc(100vh - 100px)',
    backgroundColor: '#fff',
    boxShadow: 'inset -1px 0px 0px rgba(148, 157, 176, 0.24)',
    transform: 'translate(0px, 0px)',
    transition: 'transform 0.2s',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  loader: {
    color: '#5fbb50',
  },
  loaderContainer: {
    marginTop: '80px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  sidebarClose: {
    transform: 'translate(-300px, 0px)',
    transition: 'transform 0.2s',
  },
  wrapperContainer: {
    position: 'relative',
  },
  iconArrow: {
    position: 'absolute',
    top: 9,
    right: 12,
    cursor: 'pointer',
    color: '#687285',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.85)',
    },
  },
  iconArrowActive: {
    color: ({ primaryColor }: Props) => primaryColor || '#5FBB50',
    '&:hover': {
      color: ({ primaryColor }: Props) => (primaryColor ? darken(primaryColor, 0.3) : '#469339'),
    },
  },
  menuButton: {
    marginRight: '60px',
    cursor: 'pointer',
  },
  innerMenuButton: {
    margin: '15px 0px 23px 23px',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    width: '260px',
    transition: 'width 0.2s',
  },
  iconsView: {
    width: '88px',
    transition: 'width 0.2s',
    overflowX: 'hidden',
  },
  categoryTitle: {
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    margin: '34px 0px 16px 25px',
    width: 'auto',
    transition: 'width 0.2s',
    letterSpacing: '1.2px',
    fontWeight: 700,
    color: '#949DB0',
  },
  categoryTitleTransparent: {
    width: '88px',
    transition: 'width 0.2s',
    color: 'transparent',
  },
  itemTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontFamily: 'Lato-Normal, sans-serif',
    color: '#687285',
    cursor: 'pointer',
    padding: '12px 20px 12px 25px',
    borderRight: 'solid 2px transparent',
    width: '100%',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(104, 114, 133, 0.05)',
    },
    textDecoration: 'none',
  },
  itemTitleActive: {
    color: ({ primaryColor }: Props) => primaryColor || '#5FBB50',
    borderRight: ({ primaryColor }: Props) =>
      primaryColor ? `solid 2px ${primaryColor}` : 'solid 2px #5FBB50',
    backgroundColor: ({ primaryColor }: Props) =>
      primaryColor ? lighten(primaryColor, 0.92) : 'rgba(95, 187, 80, 0.08)',
    '&:hover': {
      backgroundColor: ({ primaryColor }: Props) =>
        primaryColor ? lighten(primaryColor, 0.92) : 'rgba(95, 187, 80, 0.08)',
    },
  },
  itemTitleTransparent: {
    width: '88px',
  },
  iconDown: {
    transform: 'rotate(90deg)',
  },
  subitemTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'Lato-Normal, sans-serif',
    color: '#687285',
    cursor: 'pointer',
    padding: '12px 0px 12px 48px',
    '&:hover': {
      backgroundColor: 'rgba(104, 114, 133, 0.05)',
    },
    textDecoration: 'none',
  },
  subitemTitleActive: {
    fontSize: '14px',
    color: '#303642',
    fontFamily: 'Lato-SemiBold, sans-serif',
    padding: '12px 0px 12px 46px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tooltipTitle: {
    fontSize: '15px',
    fontFamily: 'Lato-SemiBold, sans-serif',
    color: '#000',
    paddingTop: '15px',
    marginBottom: '15px',
  },
  tooltipTitleNoMargin: {
    marginBottom: '0px',
  },
  dotIconBig: {
    marginRight: '20px',
    color: ({ primaryColor }: Props) => primaryColor || '#5FBB50',
    fontSize: '12px',
  },
  dotIcon: {
    marginRight: '22px',
    color: '#687285',
    fontSize: '8px',
  },
  tooltipStyles: {
    color: '#000',
    marginRight: '9px',
  },
  tooltipStylesActive: {
    color: '#000',
    marginRight: '6px',
  },
  tooltipLinkStyles: {
    color: '#000',
    padding: '12px 10px',
    fontFamily: 'Lato-Normal, sans-serif',
  },
  tooltip: {
    padding: '0px 15px 15px 15px',
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '8px',
    minWidth: '200px',
    boxSizing: 'border-box',
    boxShadow:
      '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  itemDisabled: {
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icons: {
    fontSize: '18px',
    marginRight: '18px',
    lineHeight: '17px',
  },
  iconsBig: {
    fontSize: '21px',
    marginRight: '0px',
    marginLeft: '9px',
  },
  labelItem: {
    backgroundColor: (props) => props?.labelBc,
    color: (props) => props?.labelColor,
    padding: '4px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontSize: '8px',
    marginLeft: '4px',
    position: 'absolute',
    left: 'calc(100% + 4px)',
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    fontFamily: 'Lato-SemiBold, sans-serif',
  },
  titleItemContainer: {
    display: 'flex',
    position: 'relative',
  },
  subitemsContainer: {
    position: 'relative',
    '& > div': {
      marginLeft: '16px',
      display: ({ areSubitemsShown }: Props) => (areSubitemsShown ? 'block' : 'none'),
    },
  },
  divider: {
    margin: '12px 16px',
    height: '1px',
    backgroundColor: 'rgba(148, 157, 176, 0.24)',
  },
  versionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '2rem auto 1rem auto',
    height: '100%',
    paddingBottom: '1rem',
  },
  version: {
    fontSize: '14px',
    fontFamily: 'Lato-Normal, sans-serif',
    color: '#687285',
    cursor: 'pointer',
  },
}));

export default styles;
