import React from 'react';
import classNames from 'classnames';
import { Icon } from '@zaveit/icons';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';

import useStyles from './Menu.styles';

const SubItem = ({
  subitem,
  itemsOpen,
  isActive,
  showTitles,
  isTooltip = false,
  toggleItem,
  setIsRightSidebarOpened,
  primaryColor = '',
}) => {
  const classes = useStyles({ primaryColor });

  return (
    <>
      <a
        key={subitem.title}
        className={classNames({
          [classes.subitemTitle]: true,
          [classes.subitemTitleActive]: isActive,
          [classes.tooltipLinkStyles]: isTooltip,
        })}
        aria-hidden="true"
        href={`${window.location.origin}${subitem.url}`}
      >
        <Icon
          iconName="fiber_manual_record"
          className={classNames({
            [classes.dotIcon]: !isActive,
            [classes.dotIconBig]: isActive,
            [classes.tooltipStyles]: isTooltip && !isActive,
            [classes.tooltipStylesActive]: isTooltip && isActive,
          })}
        />
        {showTitles && <span>{subitem.title}</span>}
      </a>
      {!isTooltip && subitem.items?.length > 0 && (
        <KeyboardArrowRightRoundedIcon
          role="button"
          aria-hidden="true"
          onClick={() => {
            toggleItem(subitem.title);
            setIsRightSidebarOpened(false);
          }}
          className={classNames({
            [classes.iconArrow]: true,
            [classes.iconDown]: itemsOpen.includes(subitem.title),
          })}
        />
      )}
    </>
  );
};

export default SubItem;
