import React, { MutableRefObject, FormEvent } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { Icon } from '@zaveit/icons';
import { Box, Typography, FormControl, CircularProgress } from '@material-ui/core';
import { Button as UIKitButton } from '@zaveit/uikit';

import Button from './Button';
import useStyles from './Login.styles';
import { StepsEnum } from './constants';

export type TVerification = {
  step: string;
  count: number;
  total: number;
  hasCode: boolean;
  isLoading: boolean;
  verificationTitle: string;
  verificationSubtitle: string;
  verificationError: string;
  inputRef: MutableRefObject<ReactCodeInput>;
  handleBack: () => void;
  handleChangeLoginState: ({
    emailCode,
    smsCode,
  }: {
    emailCode?: string;
    smsCode?: string;
  }) => void;
  handleSendVerificationCodes: (e: MouseEvent) => void;
  handleResendCode: () => Promise<void>;
};

const Verification = ({
  step,
  count,
  total,
  hasCode,
  isLoading,
  verificationTitle,
  verificationSubtitle,
  verificationError,
  inputRef,
  handleBack,
  handleChangeLoginState,
  handleSendVerificationCodes,
  handleResendCode,
}: TVerification) => {
  const classes = useStyles();

  const progress = (count / total) * 100;
  const hasStepper = total > 1;

  const handleInputCode = (code) => {
    if (step === StepsEnum.email) {
      handleChangeLoginState({
        emailCode: code,
      });
    }

    if (step === StepsEnum.sms) {
      handleChangeLoginState({
        smsCode: code,
      });
    }
  };

  return (
    <Box className={classes.codesContainer}>
      <form
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          handleSendVerificationCodes(e as any);
        }}
      >
        <Box display="flex" mb={hasStepper ? '20px' : ''}>
          {hasStepper && (
            <Box className={classes.stepper}>
              <Typography className={classes.stepperText}>
                <Typography component="span" className={classes.greenText}>
                  {count}
                </Typography>
                {`/${total}`}
              </Typography>
              <CircularProgress
                value={100}
                className={classes.fakeProgressBar}
                variant="determinate"
                size={44}
              />
              <CircularProgress
                value={progress}
                className={classes.progressBar}
                variant="determinate"
                size={44}
              />
            </Box>
          )}
          <Box className={classes.verificationTitle}>
            {hasStepper && (
              <Typography className={classes.greenTitle}>{`Step ${count}:`}</Typography>
            )}
            <Typography>{verificationTitle}</Typography>
          </Box>
        </Box>
        <Box className={classes.verificationSubtitle}>{verificationSubtitle}</Box>
        <FormControl className={classes.inputsWrapper}>
          <Box className={classes.codeContainer}>
            <ReactCodeInput
              onComplete={handleInputCode}
              fieldWidth={56}
              fieldHeight={56}
              autoFocus
              type="number"
              ref={inputRef}
            />
          </Box>
          {step === StepsEnum.email && (
            <div className={classes.infoContainer}>
              <Icon iconName="info" className={classes.info} />
              <span>
                Can’t find your code? The email might be filtered as spam on some email clients.
              </span>
            </div>
          )}
          {verificationError && (
            <Box className={classes.errorVerification}>{verificationError}</Box>
          )}
          <Box className={classes.navigationContainer}>
            <Box className={classes.resendQuestionContainer}>
              <Typography className={classes.resendQuestion}>
                Didn&apos;t receive a code?
              </Typography>
              <Typography
                className={classes.resendBtn}
                onClick={handleResendCode}
                role="button"
                aria-hidden="true"
              >
                Resend a code
              </Typography>
            </Box>
            <Box className={classes.buttonsContainer}>
              <Button className={classes.whiteButton} onClick={handleBack} type="button">
                Back
              </Button>

              <UIKitButton
                // className={classes.greenButton}
                onClick={(e: MouseEvent) => {
                  handleSendVerificationCodes(e);
                }}
                disabled={!hasCode || (hasCode && isLoading)}
                type="submit"
                styleClass="black"
              >
                {isLoading ? <CircularProgress size={15} className={classes.loader} /> : 'Verify'}
              </UIKitButton>
            </Box>
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export default Verification;
