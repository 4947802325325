// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import Colors from '../styles/Color.styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    fontFamily: 'Lato-Normal, sans-serif',
    '& .MuiFormLabel-root.Mui-focused': {
      color: Colors.mainGreen,
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Lato-Normal, sans-serif !important',
    },
    '& .MuiInputLabel-outlined': {
      transform: ({ smallInputs }) =>
        smallInputs ? 'translate(14px, 14px) scale(1)' : 'translate(14px, 20px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -5px) scale(0.75)',
    },
  },
  textField: {
    width: '100%',
    outline: 'none',
  },
  cssOutlinedInput: {
    height: '56px',
    '&$focused $notchedOutline': {
      border: `2px solid  ${Colors.mainGreen} !important`,
    },
    '&$error $notchedOutline': {
      border: `2px solid  ${Colors.error}`,
    },
  },
  focused: {},
  errorInput: {},
  notchedOutline: {
    border: `solid 1px ${Colors.lightGrey}`,
    borderRadius: '8px',
  },
  inputDisabled: {
    border: `solid 1px ${Colors.lightGrey}`,
  },
  error: {
    textAlign: 'left',
    padding: '10px 0px 0px 5px',
    fontSize: '14px',
    fontFamily: 'Lato-Normal, sans-serif',
    letterSpacing: '0.5px',
    color: Colors.red,
  },
}));

export default useStyles;
