import axios from 'axios';

const getInstance = (config) =>
  axios.create({ baseURL: config.baseAuthUrl, withCredentials: true });

export const loginUser = (username, config) => {
  const instance = getInstance(config);
  return instance.post(`${config.baseAuthUrl}/v1/auth/step`, { username });
};

type VerifyData = {
  username: string;
  code: number;
};

export const verifyUser = (data: VerifyData, config) => {
  const instance = getInstance(config);
  return instance.post(`${config.baseAuthUrl}/v1/auth/step`, {
    ...data,
  });
};
