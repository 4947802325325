// @ts-check
const isDevelopment = window.location.hostname === 'localhost';
const environment = isDevelopment ? 'development' : 'prod';

const domain = window.location.host.substring(
  window.location.host.lastIndexOf('.', window.location.host.lastIndexOf('.') - 1) + 1,
);

const configs = {
  development: {
    baseAuthUrl: 'http://localhost:3334',
    baseVsaasUrl: 'http://localhost:3340',
    baseUserUrl: 'http://localhost:3333',
    baseFileUrl: 'http://localhost:3370',
    baseAgreementUrl: 'http://localhost:3341',
  },
  prod: {
    baseAuthUrl: `${window.location.protocol}//api.${domain}`,
    baseVsaasUrl: `${window.location.protocol}//api.${domain}`,
    baseUserUrl: `${window.location.protocol}//api.${domain}`,
    baseFileUrl: `${window.location.protocol}//api.${domain}`,
    baseAgreementUrl: `${window.location.protocol}//api.${domain}`,
  },
};

const config = configs[environment] || configs.development;

export default config;
