// @ts-nocheck
import { makeStyles } from '@material-ui/core';

type Props = {
  open?: boolean;
};

const styles = makeStyles((theme) => ({
  avatarContainerRoot: {
    '&.MuiPaper-elevation8': {
      boxShadow:
        '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
    },
  },
  account: {
    color: (props) => props?.iconColor || '#FFFFFF',
    fontSize: '24px',
    '&.MuiButtonBase-root': {
      padding: 0,
      marginRight: '4px',
    },
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
  menuButton: {
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    borderRadius: '32px',
    backgroundColor: ({ open }: Props) => (open ? 'rgba(148, 157, 176, 0.16)' : 'transparent'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.8s',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.16)',
      transition: 'background-color 0.3s',
    },
  },
  usernameCont: {
    position: 'relative',
    padding: '16px 20px',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)',
    color: '#212B36',
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
  },
  companyName: {
    fontFamily: 'Lato-Normal',
    fontSize: '14px',
    lineHeight: '22px',
    color: (props) => props?.iconColor || '#FFFFFF',
    marginLeft: '6px',
  },
  nameContainer: {
    display: 'flex',
  },
  iconMenu: {
    fontSize: '22px',
    marginRight: '12px',
  },
  mspBadge: {
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '12px',
    lineHeight: '20px',
    backgroundColor: 'rgba(95, 187, 80, 0.16)',
    color: '#3E7B34',
    borderRadius: '6px',
    padding: '2px 8px',
    marginLeft: '8px',
    height: 'fit-content',
  },
  menuSubtitle: {
    width: '180px',
    color: '#637381',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuSubtitleCapital: {
    textTransform: 'capitalize',
  },
  popoverPaper: {
    top: '55px !important',
    width: '220px',
    padding: '10px 0px',
    borderRadius: '8px',
    boxShadow:
      '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
    [theme.breakpoints.down('xs')]: {
      position: 'sticky',
      minWidth: '100%',
      borderRadius: '0',
    },
  },
  popoverRoot: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      top: '60px !important',
    },
  },
  arrow: {
    color: (props) => props?.iconColor || '#000000',
  },
  arrowRotate: {
    transform: 'rotate(180deg)',
    color: (props) => props?.iconColor || '#000000',
  },
  avatarIcon: {
    color: (props) => props?.avatarColor || '#FFFFFF',
    fontSize: '28px !important',
    borderRadius: '28px',
    width: '28px !important',
    height: '28px !important',
    backgroundColor: 'transparent',
  },
  avatarContainer: {
    width: '23px',
    height: '23px',
    paddingTop: '2px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '30px',
    justifyContent: 'center',
    backgroundColor: (props) => props?.iconColor || '#000000',
    overflow: 'hidden',
    '& .MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: (props) => props?.avatarColor || '#FFFFFF',
      backgroundColor: (props) => props?.iconColor || '#000000',
    },
  },
  defaultAvatar: {
    backgroundColor: (props) => props?.iconColor || '#000000',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '30px',
    justifyContent: 'center',
  },
  menuLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    height: '42px',
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  menuLinkLogout: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#FF4842',
    height: '40px',
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    borderTop: 'solid 1px rgba(148, 157, 176, 0.24)',
  },
  linkItem: {
    padding: '6px 8px',
    borderRadius: '6px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(148, 157, 176, 0.08)',
    },
  },
  version: {
    color: '#949DB0',
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '10px',
    lineHeight: '16px',
    paddingLeft: '24px',
    paddingTop: '8px',
  },
  icon: {
    marginRight: '18px',
  },
  tooltipPoper: {
    fontSize: '13px',
  },
}));

export default styles;
