import React, { useRef, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Icon } from '@zaveit/icons';

import useStyles from './LangMenu.styles';

const LangMenu = ({ langs, userLang, color, setUserLang }) => {
  const classes = useStyles({ color });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current?.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangeLang = (lang) => {
    setUserLang && setUserLang(lang);
  };

  const options = langs?.map((item) => (
    <MenuItem key={item} onClick={() => handleChangeLang(item)} className={classes.menuListItem}>
      {item}
    </MenuItem>
  ));

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="Account"
        data-testid="test-accountcircle"
        onClick={handleToggle}
        classes={{
          root: classes.account,
        }}
      >
        <span className={classes.langTitle}>{userLang}</span>
        {open ? (
          <Icon iconName="arrow_drop_up" className={classes.arrow} />
        ) : (
          <Icon iconName="arrow_drop_down" className={classes.arrow} />
        )}
      </IconButton>
      <Menu
        disableScrollLock
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        PopoverClasses={{
          paper: classes.popoverPaper,
          root: classes.popoverRoot,
        }}
        id="menu-appbar"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 60,
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {options}
      </Menu>
    </>
  );
};

export default LangMenu;
