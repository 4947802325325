// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import Colors from '../../styles/Color.styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.white,
    borderRadius: '16px',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0px',
    },
  },
  backDrop: {
    backgroundColor: 'rgba(33, 37, 46, 0.7)',
  },
}));

export default useStyles;
