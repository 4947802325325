import React, { useState, useEffect } from 'react';
import { EditorState, convertFromRaw, Editor } from 'draft-js';
import { Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import { Icon } from '@zaveit/icons';
import Modal from './Modal';
import Button from './Button';
import { ModalTypes } from './types';
import { getEndUserAgreement, getCommercialAgreement } from '../api-client/agreement';
import useStyles from './AgreementModal.styles';
import { getData } from '../helpers';
import ColorStyles from '../styles/Color.styles';

interface Props {
  open: boolean;
  type?: string;
  handleClose?: () => void;
  handleEdit?: () => void;
  handleAcept?: () => void;
  handleDecline?: () => void;
  lang?: string;
  serviceId?: string;
  data?: any;
  loading?: boolean;
}

const AgreementModal = ({
  open,
  handleClose,
  type,
  lang,
  serviceId,
  data,
  handleAcept,
  handleDecline,
  handleEdit,
  loading,
}: Props) => {
  const settingsData = getData('siteSettings');
  const colors = settingsData?.colors;
  const primaryColor = colors?.primary_color || ColorStyles.mainGreen;
  const classes = useStyles({ primaryColor });
  const [modalData, setModalData] = useState(null);
  const [checked, setChecked] = useState([]);
  const [editorState, setEditorState] = useState({});

  const handleGetModalData = () => {
    switch (type) {
      case ModalTypes.END_USER: {
        getEndUserAgreement().then((agreement) => setModalData(agreement));
        break;
      }
      case ModalTypes.COMMERCIAL: {
        getCommercialAgreement(serviceId).then((agreement) => setModalData(agreement));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (open && !data) {
      handleGetModalData();
    }

    if (open && data) {
      setModalData(data);
    }
  }, [open, data]);

  useEffect(() => {
    if (modalData) {
      try {
        modalData.forEach((item) => {
          const body = item?.content?.[lang]?.body;
          if (body) {
            const parsedData = EditorState.createWithContent(convertFromRaw(JSON.parse(body)));
            setEditorState((prevState) => ({
              ...prevState,
              [item._id]: parsedData,
            }));
          }
        });
      } catch (e) {
        console.warn('Wrong initial value. Should be parsable JSON object');
      }
    }
  }, [modalData, lang, data]);

  const handleCheckboxChange = (id) => {
    if (type === ModalTypes.PREVIEW) {
      return;
    }
    if (!checked.includes(id)) {
      setChecked([...checked, id]);
    } else {
      const filtered = checked.filter((item: string) => item !== id);
      setChecked(filtered);
    }
  };

  const content =
    modalData &&
    modalData.map((agreement) => {
      return (
        <div key={agreement._id} className={classes.bodyItem}>
          <div className={classes.title}>{agreement?.content?.[lang]?.title}</div>
          {editorState?.[agreement._id] ? (
            <Editor editorState={editorState[agreement._id]} onChange={() => {}} />
          ) : null}
        </div>
      );
    });

  return (
    <Modal open={open} handleClose={handleClose}>
      <div
        className={classNames({
          [classes.contentContainer]: true,
          [classes.contentContainerPreview]: type === ModalTypes.PREVIEW,
        })}
      >
        {type === ModalTypes.PREVIEW && (
          <div className={classes.previewHeader}>
            <div className={classes.previewTitle}>
              <div>Preview</div>
              <button
                type="button"
                className={classNames({
                  [classes.button]: true,
                  [classes.editBtn]: type === ModalTypes.PREVIEW,
                })}
                onClick={handleEdit}
              >
                <Icon iconName="edit" className={classes.editIcon} />
                Edit
              </button>
            </div>
            <button
              type="button"
              onClick={handleClose}
              className={classNames({
                [classes.button]: true,
                [classes.closeBtn]: type === ModalTypes.PREVIEW,
              })}
            >
              <Icon iconName="close" />
            </button>
          </div>
        )}
        <div className={classes.contentBody}>{content}</div>
        <div className={classes.bottomNav}>
          <div className={classes.checkBoxContainer}>
            {modalData &&
              modalData?.map((agreement) => (
                <div
                  className={classNames({
                    [classes.checkbox]: true,
                    [classes.checkboxPreview]: type === ModalTypes.PREVIEW,
                  })}
                  role="button"
                  aria-hidden="true"
                  onClick={() => handleCheckboxChange(agreement?.content?.[lang]?.title)}
                >
                  <Checkbox
                    color="default"
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                    checked={checked.includes(agreement?.content?.[lang]?.title)}
                    onChange={(e) => handleCheckboxChange(e.target.name)}
                    name={agreement?.content?.[lang]?.title}
                    disabled={type === ModalTypes.PREVIEW}
                  />
                  <span>
                    <span>I’ve read and accept</span>
                    <span className={classes.bigTetx}>{agreement?.content?.[lang]?.title}</span>
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            styleClass="transparentButton"
            marginRight="12px"
            onClick={handleDecline}
            disabled={type === ModalTypes.PREVIEW}
          >
            Decline
          </Button>
          <Button
            width="77px"
            styleClass="activeGreen"
            disabled={checked.length < modalData?.length || type === ModalTypes.PREVIEW}
            onClick={handleAcept}
            loading={loading}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AgreementModal.defaultProps = {
  handleClose: () => {},
  handleAcept: () => {},
  handleDecline: () => {},
  handleEdit: () => {},
  type: ModalTypes.END_USER,
  lang: 'en',
  serviceId: null,
  data: '',
  loading: false,
};

export default AgreementModal;
